(function(w, d) {
    // Update tabindex for multiple items   
    function updateTabIndex(a, c) {
        a.forEach(function(b) {
            if (!b.classList.contains('expand-children')){
                b.setAttribute('tabindex', c);
            }
        });
    }
    // Find last child and add targeted class
    function findLast(i) {
        i.forEach(function(x) {
            x.lastChild.classList.add("last-child");
        });
    }
    w.addEventListener('keydown', function(ev) {
        var keyCode = ev.keyCode || ev.which;
        if (keyCode === 9 || keyCode === 13) {
            var oActive = d.activeElement,
                oMainNav = d.getElementById('main-navigation'),
                oMainNavDynamic = d.getElementById('main-nav_menu'),
                oLeftDrawer = d.querySelector('.offcanvas-drawer-left'),
                oRightDrawer = d.querySelector('.offcanvas-drawer-right'),
                oActiveSib = oActive.previousSibling,
                oActiveParent = oActive.parentElement,
                oHTMLClasslist = d.documentElement.classList,
                aMobileNavItemsLeft = d.querySelectorAll('#mobile-nav-left_menu a'),
                aMobileNavItemsRight = d.querySelectorAll('#mobile-nav-right_menu a');
            // Check if main nav is active
            if ((oMainNav != null && oMainNav.contains(oActive)) || (oMainNavDynamic != null && oMainNavDynamic.contains(oActive))) {
                // Tab keydown
                if (keyCode === 9) {
                    // Add classes to last child items depending on which nav is on page
                    if (oMainNavDynamic){
                        // Add tabindex
                        updateTabIndex(oMainNavDynamic.querySelectorAll('a'), '0');
                        if (oMainNavDynamic.querySelector('.yamm-fw') && oMainNavDynamic.querySelector('.yamm-fw .dropdown-menu ul')){
                            findLast(oMainNavDynamic.querySelectorAll('.yamm-fw .dropdown-menu ul'))
                        } else {
                            findLast(oMainNavDynamic.querySelectorAll('ul.dropdown-menu'))
                        }
                    } else {
                        findLast(oMainNav.querySelectorAll('dropdown-menu'))
                    }
                    // Open dropdowns
                    if (oActiveParent.classList.contains("dropdown") && !oActiveParent.classList.contains("open")) {
                        if (!(oActiveParent.parentElement.classList.contains("navbar-right"))){
                            oActive.parentElement.focus();
                            oActive.parentElement.classList.add("open");
                            oActive.parentElement.setAttribute('aria-expanded','true');
                        }
                    // Close dropdowns    
                    } else if (oActive.parentElement.classList.contains("last-child")) {
                        oActive.closest(".dropdown").setAttribute('aria-expanded','false');
                        oActive.closest(".dropdown").classList.remove("open");
                    }
                // Enter keydown    
                } else if (keyCode === 13) {
                    // Show drawer and update tabindex or navigate to link
                     if (oActive.classList.contains("hamburger-desktop") || oActive.classList.contains("pull-left")) {
                        oLeftDrawer.focus();
                        oHTMLClasslist.add("drawer-open", "active-left");
                        oLeftDrawer.setAttribute('aria-expanded','true');
                        updateTabIndex(aMobileNavItemsLeft, '0')
                    } else if (oActive.classList.contains("pull-right")) {
                        oRightDrawer.focus();
                        oHTMLClasslist.add("drawer-open", "active-right");
                        oRightDrawer.setAttribute('aria-expanded','true');
                        updateTabIndex(aMobileNavItemsRight, '0');
                    } else {
                        if (oActive.closest("a")){
                            w.location = oActive.closest("a");
                        } else {
                            return false;
                        }
                    }
                } 
            }    
            // Check if left and right nav toggle are active
            else if (oLeftDrawer != null && oLeftDrawer.contains(oActive) || (oRightDrawer != null && oRightDrawer.contains(oActive))) {
                // Tab keydown
                if (keyCode === 9) {
                    // Add targeting class to last child item
                    if (oLeftDrawer != null && oLeftDrawer.contains(oActive)){
                        aMobileNavItemsLeft[aMobileNavItemsLeft.length - 1].classList.add("last-child");
                    } else {
                        aMobileNavItemsRight[aMobileNavItemsRight.length - 1].classList.add("last-child");
                    }
                    // Expand siblings
                    if (oActiveSib != null && oActiveSib.classList != undefined) {
                        if (oActiveSib.classList.contains("collapsed")) {
                            var oTarget = oActiveSib.getAttribute('data-target');
                            oActiveSib.classList.remove("collapsed");
                            d.querySelector(oTarget).classList.add("in");
                        }
                    }
                    // If last nav item, close up shop
                    if (oActive.classList.contains("last-child")) {
                        oHTMLClasslist.remove("drawer-open", "active-left", "active-right");
                        d.body.focus();
                        if (oLeftDrawer != null && oLeftDrawer.contains(oActive)){
                            oLeftDrawer.setAttribute('aria-expanded','false');
                             updateTabIndex(aMobileNavItemsLeft, '-1');
                        } else {
                            oRightDrawer.setAttribute('aria-expanded','false');
                             updateTabIndex(aMobileNavItemsRight, '-1');
                        }
                    }
                } 
                // Enter keydown    
                else if (keyCode === 13) {
                    if (oActive.closest("a")){
                        w.location = oActive.closest("a");
                    } else {
                        return false
                    }
                } 
            }
        }
    })
})(window, document);